import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Home from "./pages/home/Home";
import "./App.css"
import Aos from "aos";
import "aos/dist/aos.css";
import BeAPartner from "./pages/be-a-partner/BeAPartner";
import Questionnaire from "./pages/questionnaire/Questionnaire";

function App() {

  useEffect(() => {
    Aos.init(
      {
        once: true,
        duration: 1200,
      }
    );
  }, []);
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path={"/"} element={<Home />} />
          {/* Be a partner */}
          <Route path={"/be-a-partner"} element={<BeAPartner />} />
          {/* Questionnaire */}
          <Route path={"/questionnaire"} element={<Questionnaire />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
