import React, { useEffect, useState } from "react";
import "./Questionnaire.css";
import angleDownIcon from "../../images/angle-down.svg";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import Select from "react-select";

const options = [
  { value: "Diagnosis/ Assessment", label: "Diagnosis/ Assessment" },
  { value: "Medication reminder", label: "Medication reminder" },
  { value: "Online consultation", label: "Online consultation" },
  { value: "Blood bank", label: "Blood bank" },
  { value: "Medical Note", label: "Medical Note" },
  { value: "Ambulance service", label: "Ambulance service" },
  { value: "E-pharmacy", label: "E-pharmacy" },
];
function Questionnaire() {
  // Function, States an Variables
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");

  // States
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [gender, setGender] = useState("");
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");
  const [featuredCheckboxOptions, setFeaturedCheckboxOptions] = useState(null);
  const [question5, setQuestion5] = useState("");
  const [question6, setQuestion6] = useState("");
  const [question7, setQuestion7] = useState("");
  const [question8, setQuestion8] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  //   Functions
  // handle Submit Partner Form
  const handleSubmitPartnerForm = function (e) {
    if (
      firstName !== "" &&
      lastName !== "" &&
      age !== "" &&
      gender !== "" &&
      maritalStatus !== "" &&
      question1 !== "" &&
      question2 !== "" &&
      question3 !== "" &&
      question5 !== "" &&
      question6 !== "" &&
      question7 !== ""
    ) {
      e.preventDefault();

      const featureResArr = featuredCheckboxOptions?.map((option) => {
        return option.value;
      });
      console.log(featureResArr);
      const data = {
        email,
        firstName,
        lastName,
        age,
        gender,
        maritalStatus,
        reaction: question1,
        innovative: question2,
        useful: question3,
        feature: featureResArr,
        subscribe: question5,
        competition: question6,
        recommend: question7,
        comment: question8,
      };

      setSuccessHandlerObj({ isSuccess: false, message: "" });
      setErrorHandlerObj({ hasError: false, message: "" });
      setIsLoading(true);
      const url =
        "https://simptomini-backend.herokuapp.com/api/v1/questionnaire";
      axios
        .post(url, data)
        .then(() => {
          setIsLoading(false);

          setSuccessHandlerObj({
            isSuccess: true,
            message:
              "Your response has been submitted successfully, Thank you!.",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorHandlerObj({
            hasError: true,
            message: "Something went wrong, please try again!",
          });
        });

      console.log(data);
    }
  };

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      let xCoord = 0,
        yCoord = 0;

      //scroll to page top
      window.scrollTo(xCoord, yCoord);
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });

      setFirstName("");
      setLastName("");
      setAge("");
      setGender("");
      setMaritalStatus("");
      setQuestion1("");
      setQuestion2("");
      setQuestion3("");
      setFeaturedCheckboxOptions(null);
      setQuestion5("");
      setQuestion6("");
      setQuestion7("");
      setQuestion8("");
    }
  }, [successHandlerObj]);

  useEffect(() => {
    console.log(featuredCheckboxOptions);
  }, [featuredCheckboxOptions]);

  return (
    <div className="questionnaire-container">
      {/* Navbar */}
      <Navbar />

      <div className="questionnaire-container--inner">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
        <div className="questionnaire-form-wrapper">
          <div
            className="questionnaire-form-title"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            Kindly fill the questionnaire below
          </div>

          <form data-aos="fade-right" data-aos-delay="150">
            {/* First Name  */}
            <div className="form-group first-name-form-group">
              <label htmlFor="first-name-input">First Name</label>
              <input
                type="text"
                id="first-name-input"
                required
                value={firstName}
                placeholder="Enter first name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            {/* Last Name  */}
            <div className="form-group last-name-form-group">
              <label htmlFor="last-name-input">Last Name</label>
              <input
                type="text"
                id="last-name-input"
                required
                value={lastName}
                placeholder="Enter last name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            {/* Select age  */}
            <div className="form-group age-form-group">
              <label htmlFor="age-select">Age</label>
              <select
                id="age-select"
                className="age-select-input"
                required
                value={age}
                onChange={(e) => setAge(e.target.value)}
              >
                <option value="" hidden>
                  Select age --
                </option>
                <option value="10 - 20">10 - 20</option>
                <option value="21 - 30">21 - 30</option>
                <option value="31 - 40">31 - 40</option>
                <option value="41 - 55">41 - 55</option>
                <option value="56 and above">56 and above</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Select  Gender  */}
            <div className="form-group gender-form-group">
              <label htmlFor="gender-select">Gender</label>
              <select
                id="gender-select"
                className="gender-select-input"
                required
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" hidden>
                  Select gender --
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Select  Marital status  */}
            <div className="form-group marital-status-form-group">
              <label htmlFor="marital-status-select">Marital Status</label>
              <select
                id="marital-status-select"
                className="marital-status-select-input"
                required
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.target.value)}
              >
                <option value="" hidden>
                  Select status --
                </option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Widowed">Widowed</option>
                <option value="Divorced">Divorced</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Other Questions 1 status  */}
            <div className="form-group other-questions-1-form-group questionnaire-form-group">
              <label htmlFor="other-questions-1-select">
                What is your first reaction to knowing that you can have your
                hospital walk right into your room?
              </label>
              <select
                id="other-questions-1-select"
                className="other-questions-1-select-input"
                required
                value={question1}
                onChange={(e) => setQuestion1(e.target.value)}
              >
                <option value="" hidden>
                  Select --
                </option>
                <option value="Extremely amazed">Extremely Amazed</option>
                <option value="Very Amazed">Very Amazed</option>
                <option value="Somewhat Amazed">Somewhat Amazed</option>
                <option value="Not so Amazed">Not so Amazed</option>
                <option value="Not at all Amazed">Not at all Amazed</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Other Questions 2 status  */}
            <div className="form-group other-questions-2-form-group questionnaire-form-group">
              <label htmlFor="other-questions-2-select">
                How Innovative is the product?
              </label>
              <select
                id="other-questions-2-select"
                className="other-questions-2-select-input"
                required
                value={question2}
                onChange={(e) => setQuestion2(e.target.value)}
              >
                <option value="" hidden>
                  Select --
                </option>
                <option value="Extremely Innovative">
                  Extremely Innovative
                </option>
                <option value="Very Innovative">Very Innovative</option>
                <option value="Somewhat Innovative">Somewhat Innovative</option>
                <option value="Not so Innovative">Not so Innovative</option>
                <option value="Not at all Innovative">
                  Not at all Innovative
                </option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Other Questions 3 status  */}
            <div className="form-group other-questions-3-form-group questionnaire-form-group">
              <label htmlFor="other-questions-3-select">
                How useful would you consider the product to be?
              </label>
              <select
                id="other-questions-3-select"
                className="other-questions-3-select-input"
                required
                value={question3}
                onChange={(e) => setQuestion3(e.target.value)}
              >
                <option value="" hidden>
                  Select --
                </option>
                <option value="Extremely Useful">Extremely Useful</option>
                <option value="Very Useful">Very Useful</option>
                <option value="Somewhat Useful">Somewhat Useful</option>
                <option value="Not so Useful">Not so Useful</option>
                <option value="Not at all Useful">Not at all Useful</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Other Questions 4 status  */}
            <div className="form-group other-questions-4-form-group questionnaire-form-group">
              <label htmlFor="other-questions-4-select">
                Which feature of the product do you consider most useful to you
                (you can select more than one)
              </label>
              <Select
                defaultValue={featuredCheckboxOptions}
                onChange={setFeaturedCheckboxOptions}
                options={options}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                value={featuredCheckboxOptions}
                placeholder={"Select --"}
              />
            </div>

            {/* Other Questions 5 status  */}
            <div className="form-group other-questions-5-form-group questionnaire-form-group">
              <label htmlFor="other-questions-5-select">
                If the product were available today, how likely would you
                subscribe to the product?
              </label>
              <select
                id="other-questions-5-select"
                className="other-questions-5-select-input"
                required
                value={question5}
                onChange={(e) => setQuestion5(e.target.value)}
              >
                <option value="" hidden>
                  Select --
                </option>
                <option value="Extremely Likely">Extremely Likely</option>
                <option value="Very Likely">Very Likely</option>
                <option value="Somewhat Likely">Somewhat Likely</option>
                <option value="Not so Likely">Not so Likely</option>
                <option value="Not at all Likely">Not at all Likely</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Other Questions 6 status  */}
            <div className="form-group other-questions-6-form-group questionnaire-form-group">
              <label htmlFor="other-questions-6-select">
                Do you have or know of a product that currently performs this
                service?
              </label>
              <select
                id="other-questions-6-select"
                className="other-questions-6-select-input"
                required
                value={question6}
                onChange={(e) => setQuestion6(e.target.value)}
              >
                <option value="" hidden>
                  Select --
                </option>
                <option value="Yes">Yes</option>
                <option value="Not exactly">Not exactly</option>
                <option value="No">No</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Other Questions 7  */}
            <div className="form-group other-questions-7-form-group questionnaire-form-group">
              <label htmlFor="other-questions-7-select">
                How likely is it that you will recommend our product to a new
                user?
              </label>
              <select
                id="other-questions-7-select"
                className="other-questions-7-select-input"
                required
                value={question7}
                onChange={(e) => setQuestion7(e.target.value)}
              >
                <option value="" hidden>
                  Select --
                </option>
                <option value="Extremely Likely">Extremely Likely</option>
                <option value="Very Likely">Very Likely</option>
                <option value="Somewhat Likely">Somewhat Likely</option>
                <option value="Not so Likely">Not so Likely</option>
                <option value="Not at all Likely">Not at all Likely</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Other Questions 8  */}
            <div className="form-group  other-questions-8-form-group questionnaire-form-group">
              <label htmlFor="other-questions-8-input">
                In your own words, what are the features or services you will
                like to see in this product.
              </label>
              <textarea
                type="text"
                id="other-questions-8-input"
                value={question8}
                onChange={(e) => setQuestion8(e.target.value)}
              />
            </div>

            <div className="submit-form-button-wrapper">
              <button onClick={(e) => handleSubmitPartnerForm(e)}>
                Submit
              </button>
              {isLoading && <LoadingSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Questionnaire;
