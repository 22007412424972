import React, { useState, useEffect, FC } from "react";
import cancelImg from "../../images/cancel.svg";
import "./ErrorHandler.css";

const ErrorHandler = function ({ errorHandlerObj, className }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
    if (errorHandlerObj.hasError) {
      setShow(true);
    }
  }, [errorHandlerObj]);
  return (
    <>
      {show ? (
        <div className={`error-handler-container ${className || ""}`}>
          {/* {className} */}
          <div className="error-handler-header">
            <div className="error-handler-title">{errorHandlerObj.message}</div>

            <div className="cancel-error-buutton-wrrapper">
              <button onClick={() => setShow(false)}>
                <img src={cancelImg} alt="" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ErrorHandler;
