import React, { useEffect, useState } from "react";
import { decode } from "html-entities";
import axios from "axios";

function WaitlistForm({
  status,
  message,
  onValidated,
  setSuccessHandlerObj,
  successHandlerObj,
  errorHandlerObj,
  setErrorHandlerObj,
}) {
  const [email, setEmail] = useState("");

  const handleSubmitWaitlistEmail = (e) => {
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    if (email === "") {
      e.preventDefault();
      setErrorHandlerObj({
        hasError: true,
        message: "Please enter a valid email address",
      });

      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });
    const data = {
      email,
    };

    e.preventDefault();
    // On success return true

    // Call the join waitlist API
    const url = "https://simptomini-backend.herokuapp.com/api/v1/waitlist";
    axios
      .post(url, data)
      .then(() => {})
      .catch((err) => {
        setErrorHandlerObj({
          hasError: true,
          message: "Something went wrong, please try again!",
        });
      });

    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };

  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setEmail("");
    }
  }, [successHandlerObj]);

  useEffect(() => {
    // if (status === "success") {
    //   console.log(status);
    //   setSuccessHandlerObj({
    //     isSuccess: true,
    //     message: "You have successfully subscribed to our newsletter!",
    //   });
    // }
  }, [status]);

  useEffect(() => {
    console.log(successHandlerObj);
  }, [successHandlerObj]);

  return (
    <>
      <form
        className={`waitlist-form-container ${
          successHandlerObj.isSuccess ? "none" : ""
        }`}
      >
        <div className=" waitlist-email-form-group">
          <input
            type="email"
            value={email}
            placeholder="Enter your email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="submit-email-btn-wrapper">
          <button onClick={handleSubmitWaitlistEmail}>
            {status === "sending" ? "Subcribing..." : "Subscribe to newsletter"}
          </button>
        </div>
      </form>

      <div className="app-form-info">
        {status === "error" || errorHandlerObj.hasError ? (
          <div
            className="app-form-error"
            dangerouslySetInnerHTML={{
              __html: errorHandlerObj.message || getMessage(message),
            }}
          />
        ) : null}
      </div>
      {status === "success" &&
        status !== "error" &&
        !errorHandlerObj.hasError && (
          <div
            className={`email-success-container ${
              successHandlerObj.isSuccess ? "" : "none"
            }`}
          >
            {successHandlerObj.message}
          </div>
        )}
    </>
  );
}

export default WaitlistForm;
