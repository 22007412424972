import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import "./BeAPartner.css";
import angleDownIcon from "../../images/angle-down.svg";
import axios from "axios";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";

function BeAPartner() {
  // Function, States an Variables

  // States
  const [partnerType, setPartnerType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  //   Functions
  // handle Submit Partner Form
  const handleSubmitPartnerForm = function (e) {
    if (
      partnerType !== "" &&
      companyName !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phoneNumber !== ""
    ) {
      e.preventDefault();

      const data = {
        partnerType,
        companyName,
        firstName,
        lastName,
        email,
        phoneNumber,
      };

      setSuccessHandlerObj({ isSuccess: false, message: "" });
      setErrorHandlerObj({ hasError: false, message: "" });
      setIsLoading(true);
      const url = "https://simptomini-backend.herokuapp.com/api/v1/partners";
      axios
        .post(url, data)
        .then(() => {
          setIsLoading(false);

          setSuccessHandlerObj({
            isSuccess: true,
            message:
              "We have recieved your application, kindly check your mail.",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorHandlerObj({
            hasError: true,
            message: "Something went wrong, please try again!",
          });
        });
    }
  };

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      let xCoord = 0,
        yCoord = 0;

      //scroll to page top
      window.scrollTo(xCoord, yCoord);
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });

      setCompanyName("");
      setEmail("");
      setFirstName("");
      setLastName("");
      setPartnerType("");
      setPhoneNumber("");
    }
  }, [successHandlerObj]);

  return (
    <div className="be-a-partner-container">
      {/* Navbar */}
      <Navbar />

      <div className="be-a-partner-container--inner">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
        <div className="be-a-partner-form-wrapper">
          <div className="form-title" data-aos="fade-down" data-aos-delay="100">
            Kindly fill the form below
          </div>

          <form data-aos="fade-right" data-aos-delay="150">
            {/* Select partner type  */}
            <div className="form-group partner-type-form-group">
              <label htmlFor="partner-type-select">Type of Partner</label>
              <select
                id="partner-type-select"
                className="partner-type-select-input"
                required
                value={partnerType}
                onChange={(e) => setPartnerType(e.target.value)}
              >
                <option value="" hidden>
                  Select Type --
                </option>
                <option value="Doctor">Doctor</option>
                <option value="Pharmacy">Pharmacy</option>
                <option value="Hospital">Hospital</option>
                {/* <option value="NGOs">NGOs</option> */}
                <option value="NGOs">NGOs</option>
                <option value="HMOs">HMOs</option>
              </select>

              <img className="angle-down-icon" src={angleDownIcon} alt="" />
            </div>

            {/* Company Name  */}
            <div className="form-group company-name-form-group">
              <label htmlFor="company-name-input">Company Name</label>
              <input
                type="text"
                id="company-name-input"
                required
                value={companyName}
                placeholder="Enter company name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>

            {/* First Name  */}
            <div className="form-group first-name-form-group">
              <label htmlFor="first-name-input">First Name</label>
              <input
                type="text"
                id="first-name-input"
                required
                value={firstName}
                placeholder="Enter first name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            {/* First Name  */}
            <div className="form-group last-name-form-group">
              <label htmlFor="last-name-input">Last Name</label>
              <input
                type="text"
                id="last-name-input"
                required
                value={lastName}
                placeholder="Enter last name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            {/* Email  */}
            <div className="form-group email-form-group">
              <label htmlFor="email-input">Email</label>
              <input
                type="email"
                id="email-input"
                required
                value={email}
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Email  */}
            <div className="form-group phone-number-form-group">
              <label htmlFor="phone-number-input">Phone number</label>
              <input
                type="tel"
                id="phone-number-input"
                required
                value={phoneNumber}
                placeholder="Enter phone number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div className="submit-form-button-wrapper">
              <button onClick={(e) => handleSubmitPartnerForm(e)}>
                Submit
              </button>
              {isLoading && <LoadingSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BeAPartner;
