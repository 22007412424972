import React, { useState, useEffect } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import heroImg1 from "../../images/hero-img-1.png";
import heroImg2 from "../../images/hero-img-2.png";
import heroImg3 from "../../images/hero-img-3.png";
import heroImg4 from "../../images/hero-img-4.png";
import heroImg5 from "../../images/hero-img-5.png";
import bubbleEllipseIcon from "../../images/bubble-ellipse.svg";
import heartWithPlusIcon from "../../images/heart-with-plus.svg";
import docIcon from "../../images/doctors-icon.svg";
import pharmIcon from "../../images/pharm-icon.svg";
import ngoIcon from "../../images/ngo-icon.svg";
import hmoIcon from "../../images/hmo-icon.svg";
import ambulanceIcon from "../../images/ambulance-icon.svg";
import WaitlistSubscribe from "../../components/waitlist-form/WaitlistSubscribe";
import appStoreIcon from "../../images/appstore-icon.svg";
import playStoreIcon from "../../images/playstore-icon.svg";

function Home() {
  // Functions, states and variables
  const playstoreURL =
    "https://play.google.com/store/apps/details?id=com.techminte.simptominiapp";
  const appstoreURL = "https://apps.apple.com/ng/app/simptomini/id6447468138";
  // States
  const [countDays, setCountDays] = useState("");
  const [countHours, setCountHours] = useState("");
  const [countMinutes, setCountMinutes] = useState("");
  const [countSeconds, setCountSeconds] = useState("");
  const [isWaitlistFormOpen, setIsWaitlistFormOpen] = useState(false);
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  // Functions
  const integrateCountdownTimer = function () {
    // Set the date we're counting down to
    var countDownDate = new Date("May 1, 2023 12:00:00").getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountDays(days);
      setCountHours(hours);
      setCountMinutes(minutes);
      setCountSeconds(seconds);
    }, 1000);
  };

  useEffect(() => {
    integrateCountdownTimer();
  }, []);

  return (
    <div className="homepage-container">
      {/* Navbar */}
      <Navbar />

      {/* Home main section container */}
      <div className="homepage-container--inner">
        {/* Homepage Row One Section */}
        <section className="home--row-one-section">
          <div className="home--row-one-section--inner">
            {/* Left Container */}
            <div className="home--row-one-left-container" data-aos="fade-right">
              {/* Major text wrapper */}
              <div className="row-one-major-text-wrapper">
                Healthcare in your pocket!
              </div>
              {/* Row Sub text wrapper */}
              <div className="row-one-sub-text-wrapper row-sub-text-wrapper">
                We care about your health and are devoted to keeping it in the
                best condition. Get access to over 100 doctors, pharmacies
                seamlessly from your comfort zone.
              </div>

              {/* Join waitlist button */}
              <div className="join-waitlist-button-wrapper">
                {/* App Store */}
                {/* Play Store */}
                <a href={playstoreURL} target="_blank" rel="noreferrer">
                  <img src={playStoreIcon} alt="" />
                  Download on Play Store
                </a>

                <a href={appstoreURL} target="_blank" rel="noreferrer">
                  <img src={appStoreIcon} alt="" />
                  Download on App Store
                </a>
              </div>
            </div>
            {/* Right Container */}
            <div className="home--row-one-right-container" data-aos="fade-left">
              <div className="row-one-image-wrapper">
                <div className="text-rect-one row-one--text-rect-wrapper">
                  <img src={heartWithPlusIcon} alt="" />
                  <p>Stay helathy on the go</p>
                </div>
                <div className="text-rect-two row-one--text-rect-wrapper">
                  <img src={heartWithPlusIcon} alt="" />
                  <p>Understand your health better</p>
                </div>

                {/* <div className="launching-time-rect-wrapper">
                  <div className="minor-text">Launching in</div>

                  <div className="launch-countown-text">
                    {countDays}days {countHours}hrs {countMinutes}mins{" "}
                    {countSeconds}secs
                  </div>
                </div> */}
                <img
                  src={bubbleEllipseIcon}
                  alt=""
                  className="img--bubble-ellipse"
                />
                <img src={heroImg1} alt="" className="img--hero-img-1" />
              </div>
            </div>
          </div>
        </section>

        {/* Homepage Row Two Section */}
        <section className="home--row-two-section">
          {/* Left Container */}
          <div className="home--row-two-left-container" data-aos="fade-right">
            <div className="row-two-image-wrapper">
              <img src={heroImg2} alt="" className="img--hero-img" />
            </div>
          </div>
          {/* Right Container */}
          <div className="home--row-two-right-container" data-aos="fade-left">
            {/* Major text wrapper */}
            <div className="row-two-major-text-wrapper row-major-text-wrapper">
              How do you feel?
            </div>
            {/* Row Sub text wrapper */}
            <div className="row-two-sub-text-wrapper row-sub-text-wrapper">
              Waking up to a bad stomach ache or having sudden sharp headaches
              are not the best experiences, I can guess they come with a decline
              in ones overall productivity. What’s worse? Having to guess if
              it’s just a little inconvenience or a cause for concern. And
              that’s not all, finding the strength to take that trip to the
              hospital and wait in line to see a doctor might also be a lot at
              that point. Let’s help you solve these problems
            </div>
          </div>
        </section>

        {/* Homepage Row Three Section */}
        <section className="home--row-three-section">
          {/* Left Container */}
          <div className="home--row-three-left-container" data-aos="fade-right">
            {/* Major text wrapper */}
            <div className="row-three-major-text-wrapper row-major-text-wrapper">
              Find out what’s wrong
            </div>
            {/* Row Sub text wrapper */}
            <div className="row-three-sub-text-wrapper row-sub-text-wrapper">
              Finding out what’s wrong and its severity is an important first
              step. Tell us on our app how you feel and all the symptoms you’re
              experiencing, in return, we’ll tell you the possible conditions
              that are linked to those symptoms, But how do you narrow it down
              to your exact condition? Well, we will still take care of that by
              recommending a doctor who specialize in those fields.
            </div>
          </div>
          {/* Right Container */}
          <div className="home--row-three-right-container" data-aos="fade-left">
            <div className="row-three-image-wrapper">
              <img src={heroImg3} alt="" className="img--hero-img" />
            </div>
          </div>
        </section>

        {/* Homepage Row Four Section */}
        <section className="home--row-four-section">
          {/* Left Container */}
          <div className="home--row-four-left-container" data-aos="fade-right">
            <div className="row-two-image-wrapper">
              <img src={heroImg4} alt="" className="img--hero-img" />
            </div>
          </div>

          {/* Right Container */}
          <div className="home--row-four-right-container" data-aos="fade-left">
            {/* Major text wrapper */}
            <div className="row-four-major-text-wrapper row-major-text-wrapper">
              Consult a doctor online
            </div>
            {/* Row Sub text wrapper */}
            <div className="row-four-sub-text-wrapper row-sub-text-wrapper">
              Let’s help you skip the long hospital queues and unproductive
              hosptial trips, consult over 400 general and specialist doctors on
              our app. You can consult our recommended doctor, consult a
              specialist, pay for consultation and get your appointment on the
              app. We have different appointment options that suits your needs
              best. After consultation, what next?
            </div>
          </div>
        </section>

        {/* Homepage Row Five Section */}
        <section className="home--row-five-section">
          {/* Left Container */}
          <div className="home--row-five-left-container" data-aos="fade-right">
            {/* Major text wrapper */}
            <div className="row-five-major-text-wrapper row-major-text-wrapper">
              Medications at your doorstep
            </div>
            {/* Row Sub text wrapper */}
            <div className="row-five-sub-text-wrapper row-sub-text-wrapper">
              After consultations, you get comments from your doctor and also
              drug prescriptions where necessary. Now, how do you take that trip
              to the pharmacy with the feeling of unwellness. Don’t worry, we
              have that covered. We have a long list of approved pharmacies on
              our app where you can order these recommended drugs to your
              doorstep.
            </div>
          </div>
          {/* Right Container */}
          <div className="home--row-five-right-container" data-aos="fade-left">
            <div className="row-five-image-wrapper">
              <img src={heroImg5} alt="" className="img--hero-img" />
            </div>
          </div>
        </section>

        {/* Homepage Row Six Section */}
        <section id="waitlist" className="home--row-six-section">
          <div className="home--row-six-section--inner" data-aos="fade-up">
            {/* Major text wrapper */}
            <div className="row-six-major-text-wrapper row-major-text-wrapper">
              A complete package
            </div>
            {/* Row Sub text wrapper */}
            <div className="row-six-sub-text-wrapper row-sub-text-wrapper">
              Simptomini gives you the full healthcare package but without the
              stress. Say goodbye to hospital queues, say hello to easy wellness
              with us
            </div>

            <div className="waitlist-and-partner-button-wrapper">
              {/* Join waitlist button */}
              <div className="join-waitlist-button-wrapper lower--join-waitlist-button-wrapper">
                <button
                  onClick={() => {
                    // setSuccessHandlerObj({
                    //   isSuccess: false,
                    //   message: "",
                    // });
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Download the app
                </button>
              </div>
            </div>

            <div className="waitlist-form-wrapper">
              <WaitlistSubscribe
                successHandlerObj={successHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
                errorHandlerObj={errorHandlerObj}
                setErrorHandlerObj={setErrorHandlerObj}
              />
            </div>
          </div>
        </section>

        {/* Homepage Row Seven Section */}
        <section className="home--row-seven-section" id="our-partners">
          <div className="home--row-seven-section--inner">
            {/* Major text wrapper */}
            <div
              className="row-seven-major-text-wrapper row-major-text-wrapper"
              data-aos="fade-up"
            >
              Our Partners
            </div>

            {/* Partners wrapper*/}
            <div className="partners-container">
              {/* Doctors */}
              <div
                className="partner-wrapper partners--doctors"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <img src={docIcon} alt="" />
                <p>Doctors</p>
              </div>

              {/* Pharmacists */}
              <div
                className="partner-wrapper partners--pharm"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <img src={pharmIcon} alt="" />
                <p>Pharmacists</p>
              </div>

              {/* Ambulance */}
              <div
                className="partner-wrapper partners--ambulance"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <img src={ambulanceIcon} alt="" />
                <p>Ambulance services</p>
              </div>

              {/* HMO's */}
              <div
                className="partner-wrapper partners--hmo"
                data-aos="fade-left"
                data-aos-delay="400"
              >
                <img src={hmoIcon} alt="" />
                <p>HMO's </p>
              </div>

              {/* HMO's */}
              <div
                className="partner-wrapper partners--ngo"
                data-aos="fade-left"
                data-aos-delay="500"
              >
                <img src={ngoIcon} alt="" />
                <p>NGO's </p>
              </div>
            </div>

            <div className="partners-container--MOBILE">
              {/* HMO's */}
              <div
                className="partner-wrapper partners--hmo--MOBILE"
                data-aos="fade-left"
                data-aos-delay="400"
              >
                <img src={hmoIcon} alt="" />
                <p>HMO's </p>
              </div>

              {/* HMO's */}
              <div
                className="partner-wrapper partners--ngo--MOBILE"
                data-aos="fade-left"
                data-aos-delay="500"
              >
                <img src={ngoIcon} alt="" />
                <p>NGO's </p>
              </div>
            </div>

            {/* Be a partner Link Wrapper */}
            <div
              className="home--partner-with-us-link-wrapper"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <Link to={"/be-a-partner"}>Partner with us</Link>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
