import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import companyLogo from "../../images/company-logo.png";
import facebookIcon from "../../images/facebook-icon.png";
import instagramIcon from "../../images/instagram-icon.png";
import twitterIcon from "../../images/twitter-icon.png";
import whatsappIcon from "../../images/whatsapp-icon.png";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-container--inner">
        {/* Left Section */}
        <div className="footer-left-section-wrapper">
          <div className="footer-company-logo-wrapper">
            <img src={companyLogo} alt="" />
          </div>

          <div className="copyright-text">© Simptomini 2022</div>
        </div>

        {/* Right Section */}
        <div className="footer-right-section-wrapper">
          {/* Social Media Link Container */}
          <div className="social-media-links-container">
            {/* Container Title */}
            <div className="social-media-links-title">
              Follow us on social media
            </div>

            <div className="social-media-links-section">
              {/* Facebook Icon */}
              <div className="social-link facebook-link">
                <Link to={"#"}>
                  <img src={facebookIcon} alt="" />
                </Link>
              </div>

              {/* Instagram Icon */}
              <div className="social-link instagram-link">
                <Link to={"#"}>
                  <img src={instagramIcon} alt="" />
                </Link>
              </div>

              {/* twitter Icon */}
              <div className="social-link twitter-link">
                <Link to={"#"}>
                  <img src={twitterIcon} alt="" />
                </Link>
              </div>

              {/* whatsapp Icon */}
              <div className="social-link whatsapp-link">
                <Link to={"#"}>
                  <img src={whatsappIcon} alt="" />
                </Link>
              </div>
            </div>
          </div>

          {/* Privacy Policy Link Container */}
          <div className="privacy-policy-link-container">
            {/* Container Title */}
            <div className="privacy-policy-link-title">Privacy policy</div>
            <div className="privacy-policy-link">
              <Link to={"#"}>Read here</Link>
            </div>
          </div>

          {/*Terms and conditions Link Container */}
          <div className="terms-and-conditions-link-container">
            {/* Container Title */}
            <div className="terms-and-conditions-link-title">
              Terms and conditions
            </div>
            <div className="terms-and-conditions-link">
              <Link to={"#"}>Read here</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
