import MailchimpSubscribe from "react-mailchimp-subscribe";
import WaitlistForm from "./WaitlistForm";

const WaitlistSubscribe = ({
  successHandlerObj,
  setSuccessHandlerObj,
  errorHandlerObj,
  setErrorHandlerObj,
}) => {
  const URL =
    "https://techminte.us9.list-manage.com/subscribe/post?u=0e0eb95076955e39a1857c23e&amp;id=922940c001";

  return (
    <MailchimpSubscribe
      url={URL}
      render={(props) => {
        const { subscribe, status, message } = props || {};
        return (
          <WaitlistForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            successHandlerObj={successHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
            errorHandlerObj={errorHandlerObj}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        );
      }}
    />
  );
};

export default WaitlistSubscribe;
