import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import companyLogo from "../../images/company-logo.png";
import navBurgerIcon from "../../images/nav-burger-icon.svg";
import navCloseIcon from "../../images/nav-close-icon.svg";

function Navbar() {
  // States, Functions and Variables
  const location = useLocation();
  // States

  const [isCurrentPageHome, setIsCurrentPageHome] = useState(false);

  // Functions
  // Handle Open Mobile Navbar Slide Dropdown
  const handleOpenMobileNavbarDropdown = function () {
    const mobileSlideNavbarModal = document.querySelector(
      ".MOBILE__slide-nav-bar-section"
    );
    const navbarContainer = document.querySelector(".navbar-container");

    // Toggle the visibility of the slide navbar modal and the ovrflow of the layout container
    mobileSlideNavbarModal.classList.toggle("none");
    navbarContainer.classList.toggle("overflow-hidden");
  };

  // Handle Close Mobile Navbar Slide Dropdown
  const handleCloseMobileNavbarDropdown = function () {
    const mobileSlideNavbarModal = document.querySelector(
      ".MOBILE__slide-nav-bar-section"
    );
    const navbarContainer = document.querySelector(".navbar-container");

    // Toggle the visibility of the slide navbar modal and the ovrflow of the layout container
    mobileSlideNavbarModal.classList.toggle("none");
    navbarContainer.classList.toggle("overflow-hidden");
  };

  useEffect(() => {
    // Close the mobile navbar if the location object changes, i.e if the uurl changes
    const mobileSlideNavbarModal = document.querySelector(
      ".MOBILE__slide-nav-bar-section"
    );
    const navbarContainer = document.querySelector(".navbar-container");
    // Toggle the visibility of the slide navbar modal and the ovrflow of the layout container
    mobileSlideNavbarModal.classList.add("none");
    navbarContainer.classList.remove("overflow-hidden");

    if (
      location.pathname === "/be-a-partner" ||
      location.pathname.includes("/questionnaire")
    ) {
      setIsCurrentPageHome(false);
    } else {
      setIsCurrentPageHome(true);
    }
  }, [location]);
  return (
    <div className="navbar-container">
      <div className="MOBILE__slide-nav-bar-section none">
        <div className="MOBILE__slide-nav-bar-section--inner ">
          {/* Left Section */}
          <div className="navbar-left-section-wrapper">
            <div className="navbar-company-logo-wrapper">
              <Link to={"/"}>
                <img src={companyLogo} alt="" />
              </Link>
            </div>
          </div>
          {/* Mobile Nav Close button  container */}
          <div className="mobile-navclose-btn-container">
            <button onClick={handleCloseMobileNavbarDropdown}>
              <img src={navCloseIcon} alt="" />
            </button>
          </div>
        </div>

        {/* Mobile navbar main wrapper */}
        <div className="mobile-main-nav-wrapper">
          {/* Our partners Link */}
          {isCurrentPageHome && (
            <div className="mobile-nav-link mobile-our-partners-nav-link">
              <a href={"#our-partners"}>Our partners</a>
            </div>
          )}

          {/* Blog Link */}
          {/* <div className="mobile-nav-link mobile-blog-nav-link">
            <Link to={"#"}>Blog</Link>
          </div> */}

          {/* Be a partne Link */}
          <div className="mobile-nav-link mobile-be-a-partner-nav-link">
            <Link to={"/be-a-partner"}>Be a partner</Link>
          </div>

          {/* Download  Link */}
          {/* {isCurrentPageHome && (
            <div className="mobile-nav-link mobile-download-nav-link">
              <a href={"#waitlist"}>Join the waitlist</a>
            </div>
          )} */}
        </div>
      </div>

      <div className="navbar-container--inner">
        {/* Left Section */}
        <div className="navbar-left-section-wrapper" data-aos="fade-down">
          <div className="navbar-company-logo-wrapper">
            <Link to={"/"}>
              <img src={companyLogo} alt="" />
            </Link>
          </div>
        </div>

        {/* Mobile Nav burger button  container */}
        <div
          className="mobile-navburger-btn-container mobile-header-action-btn"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          <button onClick={handleOpenMobileNavbarDropdown}>
            <img src={navBurgerIcon} alt="" />
          </button>
        </div>

        {/* Right Side */}
        <div
          className="navbar-right-section-wrapper"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          {/* Our Partners Link Wrapper */}
          {isCurrentPageHome && (
            <div className="navbar-link-wrapper our-partners-link-wrapper left-action-link-wrapper">
              <a href={"#our-partners"}>Our partners</a>
            </div>
          )}

          {/* Blog Link Wrapper */}
          {/* <div className="navbar-link-wrapper blog-link-wrapper left-action-link-wrapper">
            <Link to={"#"}>Blog</Link>
          </div> */}

          {/* Be a partner Link Wrapper */}

          <div className="navbar-link-wrapper be-a-partner-link-wrapper right-action-link-wrapper">
            <Link to={"/be-a-partner"}>Be a partner</Link>
          </div>

          {/* Download Link Wrapper */}
          {/* {isCurrentPageHome && (
            <div className="navbar-link-wrapper download-link-wrapper right-action-link-wrapper">
              <a href={"#waitlist"}>Join the waitlist</a>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
